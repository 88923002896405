// React core
import React, { FC, ReactNode } from 'react';

// Vendor
import cx from 'classnames';

// Types
import { HeadingSizes } from '../../types/headingSizes';

// Styles
import styles from './styles.module.scss';

interface ITitleProps {
  children?: ReactNode;
  className?: string;
  isLarger?: boolean;
  variant?: HeadingSizes;
}

const Title: FC<ITitleProps> = ({
  children,
  className,
  isLarger,
  variant = 'h1',
}) => {
  const Tag = `${variant}` as keyof JSX.IntrinsicElements;
  return (
    <Tag
      className={cx(isLarger ? styles.TitleLarger : styles.Title, className)}
    >
      {children}
    </Tag>
  );
};

export default Title;
