import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import selectPlugin from '@rematch/select';

import models, { RootModel } from './models';

const store = init<RootModel>({
  models,
  plugins: [selectPlugin()],
});

const { dispatch } = store;
export type Store = typeof store;
export type IRootState = RematchRootState<RootModel>;
export type Dispatch = RematchDispatch<RootModel>;

export const { select } = store;
export default store;
export { dispatch };
