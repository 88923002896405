import { InternalAxiosRequestConfig } from 'axios';
import { getToken } from '../../authentication';

const axiosRequest = async (config: InternalAxiosRequestConfig) => {
  const token = await getToken();
  if (config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export default axiosRequest;
