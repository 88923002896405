import AppConfig from '../../config';
import getNewRelicScript from './script';

const config = {
  accountId: AppConfig?.REACT_APP_NEWRELIC_ACCOUNT_ID,
  trustKey: AppConfig?.REACT_APP_NEWRELIC_TRUST_KEY,
  agentId: AppConfig?.REACT_APP_NEWRELIC_AGENT_ID,
  licenseKey: AppConfig?.REACT_APP_NEWRELIC_LICENSE_KEY,
  appId: AppConfig?.REACT_APP_NEWRELIC_APP_ID,
};

export async function initNewRelic(): Promise<void> {
  if (
    !AppConfig?.REACT_APP_NEWRELIC_MONITORING_ON ||
    document.getElementById('new-relic-monitoring')
  ) {
    return;
  }

  if (
    !config.accountId ||
    !config.trustKey ||
    !config.agentId ||
    !config.licenseKey ||
    !config.appId
  ) {
    console.error('NewRelic monitoring configuration incomplete, check build');
    return;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'new-relic-monitoring';
  script.text = getNewRelicScript(
    config.accountId,
    config.trustKey,
    config.agentId,
    config.licenseKey,
    config.appId
  );
  document.head.appendChild(script);
}
