// Application
import network from '.';
import { api } from '../../config';
import { IVoucherPurchase } from '../../models/vouchers';

export interface IVoucherPurchaseRequest {
  amount: number;
  brandId: string;
  currency: string;
}

export async function getVouchers(): Promise<IVoucherPurchase[]> {
  return new Promise<IVoucherPurchase[]>((resolve, reject) => {
    network
      .get(api.vouchers)
      .then(response => {
        resolve(response.data);
      })
      .catch(reason => {
        reject(reason);
      });
  });
}

export async function postVoucher(
  values: IVoucherPurchaseRequest
): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    network
      .post(api.vouchers, values)
      .then(response => {
        resolve(response.status === 200);
      })
      .catch(reason => {
        reject(reason);
      });
  });
}
