// React Core
import React, { FC, ReactNode } from 'react';

// Vendor
import cx from 'classnames';

// Styles
import styles from './styles.module.scss';

export type SectionStyles =
  | 'none'
  | 'alert'
  | 'info'
  | 'secondary'
  | 'summary'
  | 'lightText' // no background, light text
  | 'divided'
  | 'fullTextureBg'
  | 'fullTextureBgNarrow';
export type SectionSubStyles =
  | ''
  | 'squashed'
  | 'compact'
  | 'verticalCompact'
  | 'horizontalCompact'
  | 'verticalSpacedOut'
  | 'divided'
  | 'spaced';
export type SectionPositions = 'inset' | 'insetLogin';

interface ISectionProps {
  children?: ReactNode;
  className?: string;
  position?: SectionPositions;
  mainStyle?: SectionStyles;
  subStyle?: SectionSubStyles;
}

const Section: FC<ISectionProps> = ({
  children,
  className,
  position,
  mainStyle = 'none',
  subStyle,
}) => (
  <div
    className={cx(
      styles.Section,
      mainStyle && styles[mainStyle],
      subStyle && styles[subStyle],
      position && styles[position],
      className
    )}
  >
    {children}
  </div>
);

export default Section;
