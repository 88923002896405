// Application
import { api } from '../../config';
import { ITransaction, IWithdrawValues, IOneTimeCode } from '../../models/user';
import network from './';

export async function getBalance(): Promise<any> {
  return network.get(api.balance);
}

export async function withdrawSubmit(values: IWithdrawValues): Promise<any> {
  return network.post(api.withdraw, values);
}

export async function withdrawCancel(): Promise<any> {
  return network.post(api.withdrawCancel);
}

export async function withdrawConfirm(values: IOneTimeCode): Promise<any> {
  return network.post(
    api.withdrawConfirm.replace(
      '{code}',
      encodeURIComponent(values.oneTimeCode)
    )
  );
}

export async function getUser(): Promise<any> {
  return network.get(api.user);
}

export async function getTransactions() {
  return network.get<ITransaction[]>(api.transactions);
}

// TODO: reduce use of any
export async function updateUser(values: any): Promise<any> {
  return network.put(api.user, { ...values });
}

export async function deleteUser(): Promise<any> {
  return network.delete(api.user);
}
