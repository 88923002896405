// React core
import React, { FC } from 'react';

// Application
import Button from '../../components/Button';
import Header from '../../components/Header';
import Page from '../../components/Page';
import Section from '../../components/Section';
import Title from '../../components/Title';
import { routePaths } from '../../config';

const FourOhFour: FC = () => {
  const handleLoadDashboard = () => {
    window.location.href = routePaths.dashboard;
  };

  document.title = 'EQ Boost | Not found';
  return (
    <Page headerElement={<Header hideBalance={true} hideNav={true} />}>
      <Section mainStyle="fullTextureBg" subStyle="verticalSpacedOut">
        <Title>
          Oops!
          <span className="heading-sub">This page doesn't exist.</span>
        </Title>
      </Section>

      <Section mainStyle="alert">
        <p>Let's see if we can get you back on track.</p>
        <br />
        <Button
          id="btn-reload-404-page"
          type="button"
          subStyle="compact"
          onClick={handleLoadDashboard}
        >
          Go to dashboard
        </Button>
      </Section>
    </Page>
  );
};

export default FourOhFour;
