import axios from 'axios';

import { api } from '../../config';
import authInterceptor from './interceptors/authInterceptor';
import errorHandler from './interceptors/error';
import responseHandler from './interceptors/response';
import AppConfig from '../config';

const TIMEOUT_MS = 60000; // 1 minute

const getBaseUrl = (addAuthentication: boolean = true) => {
  const instance = axios.create({
    baseURL: AppConfig?.REACT_APP_API_URL || api.baseURL,
    timeout: TIMEOUT_MS,
  });

  // Add interceptors
  if (addAuthentication) {
    instance.interceptors.request.use(authInterceptor);
  }
  instance.interceptors.response.use(responseHandler, errorHandler);

  return instance;
};

// Create an instance of axios
const network = getBaseUrl();
export const networkUnauthenticated = getBaseUrl(false);

export default network;
