import { Models } from '@rematch/core';

import { brands } from './brands';
import { faqs } from './faqs';
import { user } from './user';
import { vouchers } from './vouchers';

export interface RootModel extends Models<RootModel> {
  brands: typeof brands;
  faqs: typeof faqs;
  user: typeof user;
  vouchers: typeof vouchers;
}

const models: RootModel = { brands, faqs, user, vouchers };

export default models;
