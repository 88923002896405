// React core
import React from 'react';

// Vendor
import { Navigate } from 'react-router-dom';

// Application
import { routePaths } from '../../config';
import { useIsAuthenticated } from '../../utils/authentication';

const RequireAuth = ({ children }: any) => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={routePaths.login} replace />
  );
};

export default RequireAuth;
