// Vendor
import Dinero from 'dinero.js';
import { IMonetaryValue } from '../interfaces';
import { IBrandOffer } from '../models/brands';
import { IVoucherPurchase } from '../models/vouchers';

// Defaults
export const defaultCurrencyPrecision = 2;
export const defaultCurrency = 'GBP';
export const roundingMode = 'HALF_AWAY_FROM_ZERO';

type IMoneyDinero = Dinero.Dinero;

/*
  Dinero provides a lot of methods to safely operate on numeric / monetary values.
  It accepts a value in minor currency units (i.e. value in pennies as opposed to pounds).
  And is useful to prevent floating point issues.
  Therefore, it's recommended to use when performing any operations on monetary values.
*/

const toDinero = (money: IMonetaryValue): IMoneyDinero => {
  const {
    amount,
    currency = defaultCurrency,
    precision = defaultCurrencyPrecision,
  } = money;
  return Dinero({
    // Dinero expects an integer
    // since we're multiplying by the power of 10 it should be safe to round
    // even though the result in JS might have a floating point decimal part
    amount: Math.round(amount * Math.pow(10, precision)), // convert to minor currency units, required for Dinero
    currency,
    precision,
  });
};

// This would switch from IMonetaryValue to formatted Dinero
// export const toFormattedMoney = (money: IMonetaryValue): string =>
//   toMoney(money).toFormat('£0.0.00');

export const toFormattedMoney = (money: IMonetaryValue): string => {
  const monetaryVal = toDinero({ ...money });
  return formatDinero(monetaryVal);
};

const formatDinero = (dinero: Dinero.Dinero): string =>
  dinero.hasSubUnits() ? dinero.toFormat() : dinero.toFormat('$0,0'); // trim trailing .00s, note 'EUR' sign will be shown on the wrong side

const getMonetaryValForVoucherPurchase = (
  voucherPurchase: IVoucherPurchase
): IMonetaryValue => {
  const { brand } = voucherPurchase;
  return brand && brand.offer
    ? brandOfferToMonetaryValue(brand.offer)
    : {
        amount: 0,
        currency: defaultCurrency,
        precision: defaultCurrencyPrecision,
      };
};

export const voucherPurchaseToFormattedMoney = (
  voucherPurchase: IVoucherPurchase
): string => {
  const { brand, vouchers } = voucherPurchase;
  const offerMonetaryVal = getMonetaryValForVoucherPurchase(voucherPurchase);
  if (vouchers && vouchers.length > 0) {
    return formatDinero(
      vouchers
        .map(voucher =>
          toDinero({
            ...offerMonetaryVal,
            amount: voucher.boostedValue || 0,
          })
        )
        .reduce((acc, value) => acc.add(value))
    );
  } else {
    offerMonetaryVal.amount = calculateUpliftForOffer(
      voucherPurchase.cost || 0,
      brand ? brand.offer : undefined
    );
    return toFormattedMoney(offerMonetaryVal);
  }
};

export const calculateMoneySavedForVoucherPurchase = (
  voucherPurchase: IVoucherPurchase
): IMonetaryValue => {
  const { brand } = voucherPurchase;
  const offer = brand ? brand.offer : undefined;
  const boostedMonetaryVal = getMonetaryValForVoucherPurchase(voucherPurchase);
  const costMonetaryVal = {
    ...boostedMonetaryVal,
    amount: voucherPurchase.cost || 0,
  };
  boostedMonetaryVal.amount = calculateUpliftForOffer(
    voucherPurchase.cost || 0,
    offer
  );
  return subtractMonetaryValue(boostedMonetaryVal, costMonetaryVal);
};

export const calculateMoneySavedForVoucherPurchases = (
  voucherPurchases: IVoucherPurchase[]
): IMonetaryValue => {
  if (voucherPurchases.length === 0) {
    return {
      amount: 0,
      currency: defaultCurrency,
      precision: defaultCurrencyPrecision,
    };
  }
  return (
    voucherPurchases
      .map(vp => calculateMoneySavedForVoucherPurchase(vp))
      // Only include GBP otherwise we get an assert fail from Dinero
      // This shouldn't be an issue as we're only supporting GBP currently
      .filter(mv => mv.currency && mv.currency.toLowerCase() === 'gbp')
      .reduce((acc, value) => addMonetaryValue(acc, value))
  );
};

const brandOfferToMonetaryValue = (brandOffer: IBrandOffer): IMonetaryValue => {
  return {
    amount: 0,
    currency: brandOffer.currency || defaultCurrency,
    precision: defaultCurrencyPrecision,
  };
};

const brandOfferMaxToMonetaryValue = (
  brandOffer: IBrandOffer
): IMonetaryValue => {
  return {
    ...brandOfferToMonetaryValue(brandOffer),
    amount:
      (brandOffer.valueRange ? brandOffer.valueRange.max : undefined) || 0,
  };
};

const brandOfferMinToMonetaryValue = (
  brandOffer: IBrandOffer
): IMonetaryValue => {
  return {
    ...brandOfferToMonetaryValue(brandOffer),
    amount:
      (brandOffer.valueRange ? brandOffer.valueRange.min : undefined) || 0,
  };
};

export const brandOfferMaxToFormattedMoney = (
  brandOffer: IBrandOffer
): string => {
  return toFormattedMoney(brandOfferMaxToMonetaryValue(brandOffer));
};

export const brandOfferMinToFormattedMoney = (
  brandOffer: IBrandOffer
): string => {
  return toFormattedMoney(brandOfferMinToMonetaryValue(brandOffer));
};

// Uplift calculations
export const calculateUplift = (value: number, uplift: number): number =>
  // convert to Dinero, then safely calculate value + uplift & convert back to a number
  toDinero({ amount: value })
    .multiply(1 + uplift / 100, roundingMode)
    .toUnit();

export const calculateUpliftForOffer = (
  value: number,
  offer?: IBrandOffer
): number => calculateUplift(value, getUpliftValueForOffer(offer));

export const calculateOriginalValue = (
  uplifted: number,
  uplift: number
): number =>
  toDinero({ amount: uplifted })
    .divide(1 + uplift / 100, roundingMode)
    .toUnit();

export const calculateOriginalValueForOffer = (
  uplifted: number,
  offer?: IBrandOffer
): number => calculateOriginalValue(uplifted, getUpliftValueForOffer(offer));

export const getUpliftValueForOffer = (offer?: IBrandOffer) =>
  offer == null || offer.uplift == null ? 0 : offer.uplift;

export const isMonetaryValueLessThan = (a: number, b: number): boolean => {
  return toDinero({ amount: a }).lessThan(toDinero({ amount: b }));
};

export const isMonetaryValueMoreThan = (a: number, b: number): boolean => {
  return toDinero({ amount: b }).lessThan(toDinero({ amount: a }));
};

export const isMonetaryValueLessThanOrEqual = (
  a: number,
  b: number
): boolean => {
  return toDinero({ amount: a }).lessThanOrEqual(toDinero({ amount: b }));
};

export const subtractMonetaryValue = (
  a: IMonetaryValue,
  b: IMonetaryValue
): IMonetaryValue => {
  return toMonetaryValue(toDinero(a).subtract(toDinero(b)));
};

export const addMonetaryValue = (
  a: IMonetaryValue,
  b: IMonetaryValue
): IMonetaryValue => {
  return toMonetaryValue(toDinero(a).add(toDinero(b)));
};

export const subtractValueAmount = (
  initialAmount: number,
  step: number,
  limit: number
): number => {
  // Decrease the amount by the step provided...
  const newAmount = toMonetaryValue(
    toDinero({ amount: initialAmount }).subtract(toDinero({ amount: step }))
  ).amount;
  // but only until the lower limit. Allow hitting the limit, but not going under.
  if (isMonetaryValueLessThan(newAmount, limit)) {
    return limit;
  } else {
    return newAmount;
  }
};

export const addValueAmount = (
  initialAmount: number,
  step: number,
  limit: number
): number => {
  // Increase the amount by the step provided...
  const newAmount = toMonetaryValue(
    toDinero({ amount: initialAmount }).add(toDinero({ amount: step }))
  ).amount;
  // but only until the upper limit. Allow hitting the limit, but not going over.
  if (isMonetaryValueMoreThan(newAmount, limit)) {
    return limit;
  } else {
    return newAmount;
  }
};

const toMonetaryValue = (a: Dinero.Dinero): IMonetaryValue => {
  return {
    amount: a.toUnit(),
    currency: a.getCurrency(),
    precision: a.getPrecision(),
  };
};
