// Vendor
import { AxiosError } from 'axios';

// Application
import { redirectToSignIn } from '../../authentication';
import { redirectWhenError, redirectWhenForbidden } from '../redirectsOnError';
import { responseCodes } from '../responseCodes';

const responseError = (error: AxiosError) => {
  handleRedirectWhenIsRequired(error);
};
export default responseError;

function handleRedirectWhenIsRequired(error: any) {
  const { status } = error.response;

  switch (status) {
    case responseCodes.Unauthorized:
      redirectToSignIn();
      break;
    case responseCodes.Forbidden:
      redirectWhenForbidden();
      break;
    case responseCodes.BadRequest:
      break;
    default:
      redirectWhenError();
      break;
  }
}
