// React core
import React, { FC, useEffect } from 'react';

// Vendor
import { useNavigate } from 'react-router-dom';

// Application
import ButtonLink from '../../components/ButtonLink';
import Intro from '../../components/Intro';
import Page from '../../components/Page';
import { routePaths } from '../../config';
import { useIsAuthenticated } from '../../utils/authentication';

const LoggedOut: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routePaths.dashboard);
    }
  }, [isAuthenticated, navigate]);

  document.title = 'EQ Boost | Logged out';
  return (
    <Page contentPadding={true} contentClassName="fullTextureBg">
      <Intro
        icon="openLock"
        text={<p className="narrowContentWidth">See you again soon.</p>}
        title="You've logged out"
        mainStyle="lightText"
        className="contentConstrain"
      >
        <div className="text--centered">
          <ButtonLink
            id="anchor-back-to-login"
            to={routePaths.dashboard}
            type="outlineLight"
          >
            Log back in
          </ButtonLink>
        </div>
      </Intro>
    </Page>
  );
};

export default LoggedOut;
