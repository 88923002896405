// React core
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Application
import { isDevelopment, routePaths } from '../../config';
import { useIsAuthenticated } from '../../utils/authentication';
import Intro from '../../components/Intro';
import Page from '../../components/Page';
import Button from '../../components/Button';
import { login } from '../../utils/authentication/dev';
import AppConfig from '../../utils/config';

const LoginDev: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [email, setEmail] = useState('eqboost.tester@equiniti.com');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routePaths.dashboard);
    }
  }, [isAuthenticated, navigate]);

  if (!isDevelopment) {
    return null;
  }

  async function handleSubmit(): Promise<void> {
    setError(null);
    if (!(await login(email))) {
      setError(
        `Login failed. Make sure the API is running on ${AppConfig?.REACT_APP_API_URL} and this user exists in the local DB.`
      );
    }
  }

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setError(null);
    setEmail(e.target.value);
  }

  document.title = 'EQ Boost | Development';
  return (
    <Page contentPadding={true} contentClassName="fullTextureBg">
      <Intro title="Development authentication" mainStyle="alert">
        <div
          className="text--centered"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <label>Account email</label>
          <input
            type="text"
            value={email}
            onChange={handleOnChange}
            style={{ width: '50%' }}
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Button id="btn-dev-login" onClick={handleSubmit}>
            Log in
          </Button>
        </div>
      </Intro>
    </Page>
  );
};

export default LoginDev;
