// React core
import React, { ReactNode } from 'react';

// Vendor
import cx from 'classnames';

// Application
import Footer from '../Footer';

// Styles
import styles from './styles.module.scss';

type ContentClassNames = 'fullInfoBg' | 'fullTextureBg';

interface IPageProps {
  beforeContent?: ReactNode;
  children?: ReactNode;
  className?: string;
  contentPadding?: boolean;
  contentClassName?: ContentClassNames;
  extraPaddingBottom?: boolean;
  hideFooter?: boolean;
  headerElement?: ReactNode;
  isInternalContentFw?: boolean;
}

const Page = ({
  beforeContent,
  children,
  className,
  extraPaddingBottom, // extra space if the bottom section has a background colour
  contentPadding = false,
  contentClassName,
  headerElement,
  hideFooter = false,
  isInternalContentFw = false,
}: IPageProps) => {
  const paddingOnContainer = !hideFooter && !contentPadding;
  const headerPadding = !contentPadding;
  const defaultBottomPadding = paddingOnContainer && !extraPaddingBottom;
  const extraBottomPadding = paddingOnContainer && extraPaddingBottom;
  return (
    <div
      className={cx(
        styles.Page,
        headerPadding && styles.headerPadding,
        className
      )}
    >
      {headerElement}
      {beforeContent}
      {children && (
        <main
          id="content"
          className={cx(
            contentClassName && styles[contentClassName],
            defaultBottomPadding && styles.defaultBottomPadding,
            extraBottomPadding && styles.extraBottomPadding
          )}
        >
          <div
            className={cx(
              !isInternalContentFw && styles.content,
              contentPadding && styles.contentPadding
            )}
          >
            {children}
          </div>
        </main>
      )}
      {!hideFooter && <Footer id="footer" />}
    </div>
  );
};

export default Page;
