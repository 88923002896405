// Application
import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { getFaqs } from '../utils/network/faqs';
import { IError } from '../interfaces';

interface IFAQItem {
  title: string;
  paragraphs: string[];
}

export interface IFaqsState {
  faqs: IFAQItem[] | IError | null;
}
const initialState: IFaqsState = {
  faqs: [],
};

export const faqs = createModel<RootModel>()({
  name: 'faqs',
  effects: (dispatch) => ({
    async getFaqsAsync() {
      const response = await getFaqs();
      const { data } = response;
      dispatch.faqs.updateFaqs(data);
    },
  }),
  reducers: {
    updateFaqs: (state: IFaqsState, payload: IFAQItem[]): IFaqsState => ({
      ...state,
      faqs: [...payload],
    }),
  },
  state: initialState,
});
