// Vendor
import * as msal from '@azure/msal-browser';
import { useIsAuthenticated as msalIsAuthenticated } from '@azure/msal-react';

// Application
import { isDevelopment, routePaths } from '../../config';
import { b2cPolicies, apiConfig, msalConfig } from './config';
import * as dev from './dev';

export let msalInstance: msal.PublicClientApplication;
let accountId = '';

function initialise() {
  // Create the main msalInstance instance
  msalInstance = new msal.PublicClientApplication(msalConfig);
  // Redirect after MSAL login screen
  msalInstance
    .handleRedirectPromise()
    .then((response: msal.AuthenticationResult | null) => {
      if (response) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy policies may use "acr" instead of "tfp").
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          (response.idTokenClaims as any).tfp.toUpperCase() ===
            b2cPolicies.names.signUp.toUpperCase() ||
          (response.idTokenClaims as any).tfp.toUpperCase() ===
            b2cPolicies.names.signIn.toUpperCase()
        ) {
          handleResponse(response);
        }
      }
    })
    .catch((error: any) => {
      console.error(error);
    });

  // in case of page refresh
  selectAccount();
}

function setAccount(account: msal.AccountInfo) {
  accountId = account.homeAccountId;
}

function selectAccount() {
  /**
   * See here for more information on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = msalInstance.getAllAccounts();

  if (currentAccounts.length < 1) {
    return;
  } else if (currentAccounts.length > 1) {
    /**
     * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
     * is cached as a new account, which results in more than one account in the cache. Here we make
     * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow,
     * as this is the default flow the user initially signed-in with.
     */
    const accounts = currentAccounts.filter(
      (account: any) =>
        (account.homeAccountId
          .toUpperCase()
          .includes(b2cPolicies.names.signUp.toUpperCase()) ||
          account.homeAccountId
            .toUpperCase()
            .includes(b2cPolicies.names.signIn.toUpperCase())) &&
        account.idTokenClaims.iss
          .toUpperCase()
          .includes(b2cPolicies.authorities.authorityDomain.toUpperCase()) &&
        account.idTokenClaims.aud === msalConfig.auth.clientId
    );

    if (accounts.length > 1) {
      // localAccountId identifies the entity for which the token asserts information.
      if (
        accounts.every(
          (account: any) =>
            account.localAccountId === accounts[0].localAccountId
        )
      ) {
        // All accounts belong to the same user
        setAccount(accounts[0]);
      } else {
        // Multiple users detected. Logout all to be safe.
        redirectToSignOut();
      }
    } else if (accounts.length === 1) {
      setAccount(accounts[0]);
    }
  } else if (currentAccounts.length === 1) {
    setAccount(currentAccounts[0]);
  }
}

function handleResponse(response: msal.AuthenticationResult) {
  /**
   * To see the full list of response object properties, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
   */

  if (response?.account !== null) {
    setAccount(response.account);
  } else {
    selectAccount();
  }
}

function useIsAuthenticated(): boolean {
  if (isDevelopment) {
    return dev.isAuthenticated();
  }
  return msalIsAuthenticated();
}

async function getToken(): Promise<string | null> {
  if (isDevelopment) {
    return dev.getToken();
  }

  const response = await getTokenRedirect();
  return response.accessToken;
}

function redirectToCreateAccount(email?: string | undefined) {
  if (isDevelopment) {
    dev.redirectToCreateAccount(email);
    return;
  }

  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */
  const request: msal.RedirectRequest = {
    ...b2cPolicies.authorities.signUp,
    scopes: apiConfig.b2cScopes,
  };
  request.loginHint = email;
  msalInstance.loginRedirect(request);
}

function redirectToSignIn() {
  if (isDevelopment) {
    dev.redirectToSignIn();
    return;
  }

  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */
  const loginRequest: msal.RedirectRequest = {
    ...b2cPolicies.authorities.signIn,
    scopes: apiConfig.b2cScopes,
  };

  msalInstance.loginRedirect(loginRequest); // Login redirect will navigate away, so code after this function will not run
}

function redirectToSignOut(redirectUrl?: string) {
  redirectUrl = redirectUrl ?? routePaths.loggedOut;

  if (isDevelopment) {
    dev.redirectToSignOut(redirectUrl);
    return;
  }

  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  const logoutRequest = {
    postLogoutRedirectUri: redirectUrl,
  };

  msalInstance.logoutRedirect(logoutRequest);
}

function getTokenRedirect() {
  const account = msalInstance.getAccountByHomeId(accountId) || undefined;
  const isSignInPolicy =
    (account?.idTokenClaims as any)?.tfp?.toUpperCase() ===
    b2cPolicies.names.signIn;
  const request: msal.SilentRequest = {
    authority: isSignInPolicy
      ? b2cPolicies.authorities.signIn.authority
      : b2cPolicies.authorities.signUp.authority,
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
    scopes: apiConfig.b2cScopes, // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
  };
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  request.account = account;

  return msalInstance
    .acquireTokenSilent(request)
    .then((response: any) => {
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === '') {
        throw new msal.InteractionRequiredAuthError();
      }
      return response;
    })
    .catch((error: any) => {
      console.error(
        'Silent token acquisition fails. Acquiring token using popup. \n',
        error
      );
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        request.authority = b2cPolicies.authorities.signIn.authority;
        return msalInstance.acquireTokenRedirect(request);
      } else {
        console.error(error);
      }
    });
}

/**
 * To initiate a B2C user-flow, simply make a login request using
 * the full authority string of that user-flow e.g.
 * https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_edit_profile_v2
 */
function redirectToEditProfile() {
  const request: msal.RedirectRequest = {
    ...b2cPolicies.authorities.editProfile,
    scopes: apiConfig.b2cScopes,
  };

  if (msalInstance) {
    request.loginHint = msalInstance.getAccountByHomeId(accountId)?.username;
  }

  msalInstance.loginRedirect(request);
}

function redirectToResetPassword() {
  const request: msal.RedirectRequest = {
    ...b2cPolicies.authorities.resetPassword,
    scopes: apiConfig.b2cScopes,
  };

  if (msalInstance) {
    request.loginHint = msalInstance.getAccountByHomeId(accountId)?.username;
  }

  msalInstance.loginRedirect(request);
}

async function popupLogin() {
  const request: msal.PopupRequest = {
    ...b2cPolicies.authorities.signIn,
    scopes: apiConfig.b2cScopes,
  };
  request.prompt = 'login';
  request.loginHint = msalInstance.getAccountByHomeId(accountId)?.username;
  request.redirectUri = msalConfig.auth.redirectUri + '/blank.html';
  return msalInstance.loginPopup(request);
}

async function challengeCredentials() {
  try {
    const login = await popupLogin();
    return (
      msalInstance.getAccountByHomeId(accountId)?.localAccountId ===
        login.account?.localAccountId ||
      msalInstance.getAccountByHomeId(accountId)?.homeAccountId ===
        login.account?.homeAccountId
    );
  } catch (error) {
    return false;
  }
}

function getUsername() {
  return msalInstance.getAccountByHomeId(accountId)?.username;
}

export {
  initialise,
  redirectToCreateAccount,
  redirectToSignIn,
  redirectToSignOut,
  redirectToEditProfile,
  redirectToResetPassword,
  popupLogin,
  challengeCredentials,
  getToken,
  getUsername,
  useIsAuthenticated,
};
