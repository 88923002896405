// React core
import React, { ReactNode } from 'react';

// Application
import LogoWhiteAsset from '../Logo/logo-white.svg';
import packageJson from '../../../package.json';

// Styles
import styles from './styles.module.scss';

interface IFooterProps {
  children?: ReactNode;
  id: string;
}

const Footer: React.FC<IFooterProps> = (props) => (
  <footer className={styles.Footer} data-testid={props.id}>
    <div className={styles.content}>
      <img alt="EQ Boost" className={styles.Logo} src={LogoWhiteAsset} />
      {props.children}
      <div className={styles.Links}>
        <div>
          <a
            href="https://www.shareview.co.uk/Clients/EQBoost/docs/EQBoostTandCs.pdf"
            target="_blank"
            className={styles.InlineLink}
          >
            {' '}
            Terms & Conditions{' '}
          </a>
          <a
            href="https://www.shareview.co.uk/Clients/EQBoost/docs/disclaimer.pdf"
            target="_blank"
            className={styles.InlineLink}
          >
            {' '}
            Disclaimer{' '}
          </a>
          <a
            href="https://www.shareview.co.uk/4/Info/Portfolio/Default/en/Home/Pages/privacy.aspx"
            target="_blank"
            className={styles.InlineLink}
          >
            {' '}
            Privacy Notice{' '}
          </a>
          <a
            href="https://www.shareview.co.uk/4/Info/Portfolio/default/en/Home/Pages/cookiepolicy.aspx"
            target="_blank"
            className={styles.InlineLink}
          >
            {' '}
            Cookie Policy{' '}
          </a>
        </div>
        <div className={styles.CopyRights}>
          <div>© EQ Boost - All rights reserved</div>
          <div className={styles.AppVersion}>v{packageJson.version}</div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
