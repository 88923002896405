// React Core
import React, { FC, ReactNode } from 'react';

// Vendor
import cx from 'classnames';
import ReactSVG from 'react-svg';

// Application
import Section, { SectionStyles, SectionSubStyles } from '../Section';
import Title from '../Title';

// Styles
import styles from './styles.module.scss';

// Assets
import clipboard from '../../assets/clipboard.svg';
import coin from '../../assets/coin.svg';
import letterError from '../../assets/letter-error.svg';
import openLock from '../../assets/openlock.png';
import safe from '../../assets/safe.svg';
import star from '../../assets/star.svg';
import letter from '../../assets/thank-you-circle.svg';

const icons: { [key: string]: string } = {
  clipboard,
  coin,
  letter,
  letterError,
  openLock,
  safe,
  star,
};

const isRaster = (image: string) => {
  if (
    image.includes('image/png') ||
    image.includes('image/jpg') ||
    image.includes('image/jpeg') ||
    image.includes('image/gif')
  ) {
    return true;
  }
  return false;
};

interface IIntroProps {
  children?: ReactNode;
  className?: string;
  icon?:
    | 'clipboard'
    | 'coin'
    | 'letter'
    | 'letterError'
    | 'safe'
    | 'star'
    | 'openLock';
  text?: string | React.ReactNode;
  title?: string | React.ReactNode;
  mainStyle?: SectionStyles;
  subStyle?: SectionSubStyles;
}

const Intro: FC<IIntroProps> = ({
  children,
  className,
  icon,
  title,
  text,
  mainStyle,
  subStyle,
}) => (
  <Section
    className={cx(styles.Intro, className)}
    mainStyle={mainStyle || 'info'}
    subStyle={subStyle || ''}
  >
    {title && <Title className={styles.title}>{title}</Title>}
    {icon && !isRaster(icons[icon]) && (
      <ReactSVG className={styles.icon} src={icons[icon]} wrapper="span" />
    )}
    {icon && isRaster(icons[icon]) && (
      <img className={styles.icon} src={icons[icon]} alt="" />
    )}
    {text && <div className={styles.text}>{text}</div>}
    {children && <div className={styles.children}>{children}</div>}
  </Section>
);

export default Intro;
