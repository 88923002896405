// React core
import React, { FC, ReactNode } from 'react';

// Vendor
import cx from 'classnames';

// Styles
import styles from './styles.module.scss';

export interface IButtonProps {
  children?: ReactNode;
  id: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  ariaLabel?: string;
  mainStyle?:
    | 'primary'
    | 'secondary'
    | 'secondaryOutline'
    | 'outlineLight'
    | 'tertiary'
    | 'link'
    | 'basic';
  subStyle?: 'compact';
  type?: 'button' | 'submit' | 'reset';
  onClick?(e?: any): void;
}

const Button: FC<IButtonProps> = ({
  children,
  id,
  loading,
  type = 'button',
  mainStyle = 'primary',
  subStyle,
  onClick,
  className,
  ariaLabel,
  disabled = false,
}) => (
  <button
    className={cx(
      styles.Button,
      styles[mainStyle],
      subStyle && styles[subStyle],
      className
    )}
    type={type}
    onClick={onClick}
    disabled={disabled}
    data-testid={id}
    aria-label={ariaLabel}
  >
    {loading ? 'Loading...' : children}
  </button>
);

export default Button;
