// React core
import { FC, useEffect } from 'react';

// Vendor
import { useNavigate } from 'react-router-dom';

// Application
import { routePaths } from '../../config';
import {
  redirectToSignIn,
  useIsAuthenticated,
} from '../../utils/authentication';

const Login: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      redirectToSignIn();
    } else {
      navigate(routePaths.dashboard);
    }
  }, [isAuthenticated, navigate]);

  return null;
};

export default Login;
