// Application
import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IError } from '../interfaces';
import { getBrands } from '../utils/network/brands';
import {
  getVouchers,
  IVoucherPurchaseRequest,
  postVoucher,
} from '../utils/network/vouchers';
import { IBrand } from './brands';

export interface IVoucherPurchase {
  id: string;
  created?: string;
  cost?: number;
  brandId?: number;
  brand?: IBrand;
  brandName?: string;
  status?: string;
  statusDescription?: string;
  vouchers?: IVoucher[];
}

export interface IVoucher {
  id?: string;
  status?: string;
  statusDescription?: string;
  voucherNumber?: string;
  voucherLast4Digits?: string;
  voucherLink?: string;
  cost?: number;
  uplift?: number;
  boostedValue?: number;
  balanceCheckAvailable?: boolean;
  spent?: boolean;
}

interface IVouchersState {
  vouchers: IVoucherPurchase[] | IError | null;
}

const initialState: IVouchersState = {
  vouchers: null,
};

export const vouchers = createModel<RootModel>()({
  name: 'vouchers',
  effects: (dispatch: any) => ({
    async getVouchersAsync() {
      try {
        const currentVouchers = await getVouchers();
        const allBrands = await getBrands();

        dispatch.vouchers.updateVouchers(
          currentVouchers.map((voucher) => {
            voucher.brand = allBrands.find(
              (brand) => brand.id === voucher.brandId
            );
            return voucher;
          })
        );
      } catch (response) {
        const error: IError = {
          code: response.statusCode,
          message: 'Error fetching vouchers from server',
        };

        dispatch.vouchers.updateVouchers(error);
      }
    },
    async purchaseVoucherAsync(voucherPurchase: IVoucherPurchaseRequest) {
      await postVoucher(voucherPurchase);
      await dispatch.user.getBalanceAsync(); // make sure that we get the updated balance to prevent purchases with insuficient funds
    },
    resetVouchers() {
      dispatch.vouchers.clearState();
    },
  }),
  reducers: {
    clearState: (state: any) => ({
      ...state,
      ...initialState,
    }),
    updateVouchers: (
      state: IVouchersState,
      payload: IVoucherPurchase[] | IError
    ): IVouchersState => {
      return {
        ...state,
        vouchers: payload,
      };
    },
  },
  state: initialState,
});
