// React core
import React, { FC, useEffect } from 'react';

// Vendor
import cx from 'classnames';
import { connect } from 'react-redux';

// Application
import { IRootState } from '../../store';
import { toFormattedMoney } from '../../utils/money';
import { instanceOfIError } from '../../utils/typeCheck';
import Loading from '../Loading';

// Styles
import styles from './styles.module.scss';

// Props passed in from other components
export interface IBalanceProps {
  id: string;
  mainStyle?: 'header';
}

type connectedProps = ReturnType<typeof mapDispatch> &
  ReturnType<typeof mapState>;

export type Props = connectedProps & IBalanceProps;

export const Balance: FC<Props> = ({ balance, getBalance, id, mainStyle }) => {
  useEffect(() => {
    if (
      !balance ||
      instanceOfIError(balance) ||
      (!balance.amount && balance.amount !== 0)
    ) {
      getBalance();
    }
  }, [balance, getBalance]);

  const renderBalance = () => {
    if (balance == null) {
      return <Loading type="tiny" />;
    }
    if (instanceOfIError(balance)) {
      return 'Error';
    }
    return (
      <span className={styles.money}>
        Your balance: {toFormattedMoney(balance)}
      </span>
    );
  };

  return (
    <div
      data-testid={id}
      className={cx(styles.Balance, [mainStyle ? styles[mainStyle] : ''])}
    >
      {renderBalance()}
    </div>
  );
};

const mapDispatch = (dispatch: any) => ({
  getBalance: dispatch.user.getBalanceAsync,
});

const mapState = (state: IRootState) => ({
  balance: state.user.balance,
});

export default connect(mapState, mapDispatch)(Balance);
