// React core
import React, { FC, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Vendor
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

// Application
import { isDevelopment, routePaths } from './config';
import Loading from './components/Loading';
import RequireAuth from './components/RequireAuth';
import FourOhFour from './routes/FourOhFour';
import LoggedOut from './routes/LoggedOut';
import Login from './routes/Login';
import LoginDev from './routes/Login/dev';
import { initNewRelic } from './utils/analytics/newRelic';
import Forbidden from './routes/Forbidden';
import AppConfig from './utils/config';
import { initGoogleAnalitics } from './utils/analytics/googleAnalitics';

// Lazy load components based on route (https://reactjs.org/docs/code-splitting.html)
const Account = lazy(() => import('./routes/Account'));
const ValidateActivation = lazy(() => import('./routes/ValidateActivation'));
const Activate = lazy(() => import('./routes/Activate'));
const Dashboard = lazy(() => import('./routes/Dashboard'));
const Error = lazy(() => import('./routes/Error'));
const OrderHistory = lazy(() => import('./routes/OrderHistory'));
const Transactions = lazy(() => import('./routes/Transactions'));
const VoucherDetails = lazy(() => import('./routes/VoucherDetails'));
const Withdraw = lazy(() => import('./routes/Withdraw'));
const WithdrawComplete = lazy(() => import('./routes/WithdrawComplete'));
const WithdrawWithDeleteComplete = lazy(
  () => import('./routes/WithdrawWithDeleteComplete')
);
const Faq = lazy(() => import('./routes/Faq'));

initNewRelic();

initGoogleAnalitics();

const basename = new URL(AppConfig?.PUBLIC_URL || '/', window.location.href)
  .pathname;

const App: FC = () => {
  const { inProgress } = useMsal();

  const {
    account,
    activate,
    dashboard,
    error,
    loggedOut,
    login,
    loginDev,
    orderDetails,
    orderHistory,
    transactions,
    withdraw,
    withdrawComplete,
    withdrawWithDeleteComplete,
    validateActivation,
    faq,
    forbidden,
  } = routePaths;

  const isAuthReady =
    inProgress === InteractionStatus.None ||
    inProgress === InteractionStatus.AcquireToken;

  return (
    <div className="App">
      <a href="#content" className="skip-link">
        Skip to main content
      </a>
      <Router basename={basename}>
        <Suspense fallback={<Loading type="full-page" />}>
          {isAuthReady && (
            <Routes>
              <Route
                path={account}
                element={<RequireAuth children={<Account />} />}
              />
              <Route path={activate} element={<Activate />} />
              <Route path={login} element={<Login />} />
              {isDevelopment && (
                <Route path={loginDev} element={<LoginDev />} />
              )}
              <Route
                path={validateActivation}
                element={<ValidateActivation />}
              />
              <Route path={loggedOut} element={<LoggedOut />} />
              <Route
                path={orderHistory}
                element={<RequireAuth children={<OrderHistory />} />}
              />
              <Route
                path={orderDetails}
                element={<RequireAuth children={<VoucherDetails />} />}
              />
              <Route path={error} element={<Error />} />
              <Route
                path={withdraw}
                element={<RequireAuth children={<Withdraw />} />}
              />
              <Route
                path={withdrawComplete}
                element={<RequireAuth children={<WithdrawComplete />} />}
              />
              <Route
                path={withdrawWithDeleteComplete}
                element={<WithdrawWithDeleteComplete />}
              />
              <Route
                path={transactions}
                element={<RequireAuth children={<Transactions />} />}
              />
              <Route
                path={dashboard}
                element={<RequireAuth children={<Dashboard />} />}
              />
              <Route path="*" element={<FourOhFour />} />
              <Route path={faq} element={<Faq />} />
              <Route path={forbidden} element={<Forbidden />} />
            </Routes>
          )}
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
