// React core
import React from 'react';

// Vendor
import cx from 'classnames';
import * as SvgLoaders from 'svg-loaders-react';

export interface ILoadingProps {
  mainStyle?: 'info';
  type?: 'full-page' | 'small' | 'tiny' | 'tiny-left-align' | 'tiny-inline';
}

const Loading = (props: ILoadingProps) => {
  const { mainStyle, type } = props;
  const colour = mainStyle === 'info' ? '#ffffff' : '#52525b';
  if (type && type.startsWith('tiny')) {
    const className = type.endsWith('left-align')
      ? 'd-block'
      : type.endsWith('inline')
      ? ''
      : 'center-horizontally';
    return (
      <SvgLoaders.ThreeDots
        className={className}
        fill={colour}
        width="30px"
        height="7px"
        viewBox="0 0 120 30"
      />
    );
  }
  if (type === 'small') {
    return (
      <SvgLoaders.ThreeDots
        className="center-horizontally"
        fill={colour}
        width="60px"
        height="15px"
        viewBox="0 0 120 30"
      />
    );
  }
  return (
    <div
      className={cx(
        'center-vertically-container',
        type === 'full-page' ? 'view-height-100' : 'view-height-50'
      )}
    >
      <div className="center-vertically">
        <SvgLoaders.ThreeDots fill={colour} />
      </div>
    </div>
  );
};

export default Loading;
