// React core
import React, { Component } from 'react';

// Vendor
import { connect } from 'react-redux';

// App
import { routePaths } from '../../config';
import { redirectToSignOut } from '../../utils/authentication';
import Button, { IButtonProps } from '../Button';
import AppConfig from '../../utils/config';

const mapDispatch = (dispatch: any) => ({
  logout: dispatch.user.logout,
});

type connectedProps = ReturnType<typeof mapDispatch>;
export type Props = connectedProps & IButtonProps;

export class LogoutButton extends Component<Props, {}> {
  componentDidMount() {
    import('../../routes/LoggedOut');
  }

  handleLogout = () => {
    this.props.logout!();
    redirectToSignOut(AppConfig?.PUBLIC_URL + routePaths.loggedOut);
  };

  render() {
    const { children, id, mainStyle, className } = this.props;
    return (
      <Button
        mainStyle={mainStyle}
        id={id}
        onClick={this.handleLogout}
        className={className}
      >
        {children || 'Log out'}
      </Button>
    );
  }
}

export default connect(null, mapDispatch)(LogoutButton);
