import * as msal from '@azure/msal-browser';
import AppConfig from '../config';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
 */

const clientId = AppConfig?.REACT_APP_B2C_CLIENT_ID;
const authority = AppConfig?.REACT_APP_B2C_AUTHORITY_DOMAIN;
const tenant = AppConfig?.REACT_APP_B2C_TENANT_ID;
const redirectUri = AppConfig?.REACT_APP_B2C_REDIRECT_URI;
const scope = AppConfig?.REACT_APP_B2C_SCOPE;
const editProfileFlow = AppConfig?.REACT_APP_B2C_EDIT_PROFILE_FLOW;
const resetPasswordFlow = AppConfig?.REACT_APP_B2C_RESET_PASSWORD_FLOW;
const signUpFlow = AppConfig?.REACT_APP_B2C_SIGN_UP_FLOW;
const signInFlow = AppConfig?.REACT_APP_B2C_SIGN_IN_FLOW;

export const b2cPolicies = {
  authorities: {
    authorityDomain: authority,
    editProfile: {
      authority: `https://${authority}/${tenant}/${editProfileFlow}`,
    },
    resetPassword: {
      authority: `https://${authority}/${tenant}/${resetPasswordFlow}`,
    },
    signUp: {
      authority: `https://${authority}/${tenant}/${signUpFlow}`,
    },
    signIn: {
      authority: `https://${authority}/${tenant}/${signInFlow}`,
    },
  },
  names: {
    editProfile: editProfileFlow,
    resetPassword: resetPasswordFlow,
    signUp: signUpFlow,
    signIn: signInFlow,
  },
};

export const apiConfig = {
  b2cScopes: ['openid', scope],
};

// MSAL configuration
export const msalConfig: msal.Configuration = {
  auth: {
    authority: b2cPolicies.authorities.signIn.authority, // Choose sign-up/sign-in user-flow as your default.
    clientId, // This is the ONLY mandatory field; everything else is optional.
    knownAuthorities: [b2cPolicies.authorities.authorityDomain], // You must identify your tenant's domain as a known authority.
    // redirectUri: window.location.href, // ideally we'll use this, but it requires more set up in
    redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    // If your application audience is a single tenant, you must provide an authority with your tenant id like below:
    // clientId: 'your_client_id',
    // authority: 'https://login.microsoftonline.com/{your_tenant_id}'
    // If your application is using a separate OIDC-compliant authority like "https://login.live.com" or an IdentityServer, you will need to provide it in the knownAuthorities field and set your protocolMode to "OIDC".
    // see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            // console.info(message);
            return;
          case msal.LogLevel.Verbose:
            // console.debug(message);
            return;
          case msal.LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
