import ReactGA from 'react-ga4';
import { GAEventType, IEventData } from './interfaces';
import AppConfig from '../../config';

// Public methods
export const initGoogleAnalitics = () => {
  if (AppConfig?.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize(AppConfig.REACT_APP_GA_TRACKING_ID);
  }
};

export const registerCustomEvent = (event: IEventData) => {
  ReactGA.gtag('event', GAEventType[event.eventType], event.data);
};
