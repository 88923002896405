// Vendor
import { createModel } from '@rematch/core';
import { pickBy } from 'lodash';
import { RootModel } from '.';

// Application
import { IValues as IUserValues } from '../components/AccountDetailsForm';
import { IValues as IWithdrawFormValues } from '../components/WithdrawForm';
import { IVerification as IWithdrawVerification } from '../components/OnTimeCodeForm';
import { BalanceTypes, IError, IMonetaryValue } from '../interfaces';

import { defaultCurrency } from '../utils/money';
import { updateEmail, verifyEmailCode } from '../utils/network/email';

import {
  deleteUser,
  getBalance,
  getTransactions,
  getUser,
  updateUser,
  withdrawCancel,
  withdrawConfirm,
  withdrawSubmit,
} from '../utils/network/user';
import { instanceOfIError } from '../utils/typeCheck';

export interface IUserState {
  balance: BalanceTypes;
  boostId: string | IError;
  email: string | IError | null;
  marketingOptedIn: boolean | null | IError;
  transactions: ITransaction[] | null | IError;
}

const initialState: IUserState = {
  balance: null,
  boostId: '',
  email: null,
  marketingOptedIn: null,
  transactions: null,
};

export type TransactionType =
  | 'Fund'
  | 'Purchase'
  | 'Refund'
  | 'Withdrawal'
  | 'Revocation';

export interface ITransaction {
  timestamp?: string;
  creditAmount?: number;
  debitAmount?: number;
  transactionType: TransactionType;
  voucherPurchaseId?: string;
  balance: number;
  brandName?: string;
}

export interface IWithdrawValues {
  accountName: string;
  accountNumber: string;
  amount: number;
  currency: string;
  sortCode: string;
}

export interface IOneTimeCode {
  oneTimeCode: string;
}

export const user = createModel<RootModel>()({
  name: 'user',
  effects: (dispatch: any) => ({
    logout() {
      dispatch.user.clearState();
      dispatch.vouchers.resetVouchers();
    },
    async getBalanceAsync() {
      const response = await getBalance();
      const {
        data: { amount, currency, precision },
      } = response;
      const balance: IMonetaryValue = { amount, currency, precision };
      dispatch.user.updateBalance(balance);
    },
    async getTransactionsAsync() {
      const response = await getTransactions();
      const transactions = response.data;
      dispatch.user.updateTransactions(transactions);
    },
    async withdrawAsync(payload: IWithdrawFormValues) {
      const { currency, name, withdrawAmount, sortCode, ...rest } = payload;
      const values = {
        accountName: name,
        amount: parseFloat(withdrawAmount),
        currency: currency || defaultCurrency,
        sortCode: sortCode.replace(/-/g, ''),
        ...rest,
      };
      await withdrawSubmit(values as IWithdrawValues);
    },

    async withdrawConfirmAsync(payload: IWithdrawVerification) {
      const { oneTimeCode, ...rest } = payload;
      const values = {
        oneTimeCode,
        ...rest,
      };
      await withdrawConfirm(values as IOneTimeCode);
      dispatch.user.getBalanceAsync();
    },

    async withdrawCancelAsync() {
      await withdrawCancel();
    },

    async getUserAsync() {
      const response = await getUser();
      const { boostId, email, marketingOptedIn } = response.data;
      dispatch.user.updateUser({ boostId, email, marketingOptedIn });
    },
    async updateUserAsync(userValues: IUserValues, rootState: any) {
      const boostId = rootState.user.boostId;
      // remove '', undefined & null, but keep booleans:
      const newUser = pickBy(
        {
          ...userValues,
          boostId,
        },
        (val) => !!val || val === false
      );
      const { email, marketingOptedIn } = newUser;
      dispatch.user.updateUser({ email, marketingOptedIn });
      return updateUser(newUser);
    },
    async deleteUserAsync() {
      dispatch.user.updateUser(initialState);
      await deleteUser();
    },
    async updateEmailAsync(newEmail: string) {
      return updateEmail(newEmail);
    },
    async verifyEmailCodeAsync(values: IUserValues) {
      await verifyEmailCode(values);
      dispatch.user.updateUser({ email: values.newEmail });
    },
  }),
  reducers: {
    clearState: (state: any) => ({
      ...state,
      ...initialState,
    }),
    updateBalance: (state: any, balance: any) => ({
      ...state,
      balance,
    }),
    updateTransactions: (
      state: IUserState,
      transactions: ITransaction[] | IError | null
    ) => ({
      ...state,
      transactions,
    }),
    updateUser: (state: any, userUpdate: any) => {
      if (instanceOfIError(userUpdate)) {
        const errorState: Partial<IUserState> = {
          boostId: userUpdate as IError,
          email: userUpdate as IError,
          marketingOptedIn: userUpdate as IError,
        };
        return {
          ...state,
          ...errorState,
        };
      }
      // omit boostId if not passed into here
      const { boostId, email, marketingOptedIn } = userUpdate;
      const newUser = pickBy(
        {
          boostId,
          email,
          marketingOptedIn,
        },
        (val) => !!val || val === false
      );
      return {
        ...state,
        ...newUser,
      };
    },
  },
  state: initialState,
});
