// React Core
import React, { FC } from 'react';

// Vendor
import cx from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

// Styles
import styles from './styles.module.scss';

interface IButtonLinkProps extends LinkProps {
  type?: 'primary' | 'secondary' | 'outlineLight' | 'outlineDark';
  substyle?: 'compact';
}

const ButtonLink: FC<IButtonLinkProps> = props => {
  const { className, substyle, type } = props;
  const combinedProps = {
    ...props,
    className: cx(
      styles.ButtonLink,
      className,
      type && styles[type],
      substyle && styles[substyle]
    ),
  };
  combinedProps.substyle = undefined;
  return <Link {...combinedProps} role="button" />;
};

export default ButtonLink;
