import AppConfig from '../config';
import { getB2cToken } from '../network/development';

const DEV_TOKEN_KEY = 'devToken';

function isAuthenticated(): boolean {
  return getToken() != null;
}

function getToken(): string | null {
  return window.sessionStorage.getItem(DEV_TOKEN_KEY);
}

async function login(email: string): Promise<boolean> {
  const token = await getDevelopmentToken(email);
  if (!token) {
    return false;
  }

  window.sessionStorage.setItem(DEV_TOKEN_KEY, token);
  redirect(AppConfig?.PUBLIC_URL);
  return true;
}

function redirectToCreateAccount(email?: string | undefined) {
  redirect('/dev-login');
}

function redirectToSignIn() {
  redirect('/dev-login');
}

function redirectToSignOut(redirectUrl: string) {
  window.sessionStorage.removeItem(DEV_TOKEN_KEY);
  redirect(redirectUrl);
}

async function getDevelopmentToken(email: string): Promise<string | null> {
  return getB2cToken({ email })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return null;
    });
}

function redirect(url: string) {
  window.location.replace(url);
}

export {
  isAuthenticated,
  getToken,
  login,
  redirectToCreateAccount,
  redirectToSignIn,
  redirectToSignOut,
};
