// Styles
import './index.scss';

// React core
import React from 'react';
// tslint:disable-next-line:no-submodule-imports
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { MsalProvider } from '@azure/msal-react';

// Application
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { initialise, msalInstance } from './utils/authentication';

// Initialise MSAL
initialise();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
