import { routePaths } from '../../config';

const { error, forbidden } = routePaths;

export function redirectWhenForbidden() {
  redirect(forbidden);
}

export function redirectWhenError() {
  redirect(error);
}

function redirect(url: string) {
  window.location.replace(url);
}
