const APP_CONFIG_KEY = 'appConfig';

interface IAppConfig {
  PUBLIC_URL: string;
  REACT_APP_API_URL: string;
  REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY: string;
  REACT_APP_GTM_CODE: string;
  REACT_APP_MICROSITE_URL: string;
  REACT_APP_B2C_CLIENT_ID: string;
  REACT_APP_B2C_AUTHORITY_DOMAIN: string;
  REACT_APP_B2C_TENANT_ID: string;
  REACT_APP_B2C_REDIRECT_URI: string;
  REACT_APP_B2C_SCOPE: string;
  REACT_APP_B2C_EDIT_PROFILE_FLOW: string;
  REACT_APP_B2C_RESET_PASSWORD_FLOW: string;
  REACT_APP_B2C_SIGN_UP_FLOW: string;
  REACT_APP_B2C_SIGN_IN_FLOW: string;
  REACT_APP_IS_DEVELOPMENT: boolean;
  REACT_APP_NEWRELIC_MONITORING_ON: boolean;
  REACT_APP_NEWRELIC_ACCOUNT_ID: string;
  REACT_APP_NEWRELIC_TRUST_KEY: string;
  REACT_APP_NEWRELIC_AGENT_ID: string;
  REACT_APP_NEWRELIC_LICENSE_KEY: string;
  REACT_APP_NEWRELIC_APP_ID: string;
  REACT_APP_GA_TRACKING_ID: string;
}

const windowConfig = (window as { [key: string]: any })[APP_CONFIG_KEY];
if (!windowConfig) {
  console.error(
    "Config file was not found. Make sure you to have a valid 'public\\config.js' file available."
  );
}

const AppConfig: IAppConfig = windowConfig as IAppConfig;

export default AppConfig;
