// Application
import { api } from '../../config';
import { IBrand } from '../../models/brands';
import network from './';

export async function getBrands(): Promise<IBrand[]> {
  return new Promise<IBrand[]>((resolve, reject) => {
    network
      .get<IBrand[]>(api.brands)
      .then(response => {
        resolve(response.data);
      })
      .catch(reason => {
        reject(reason);
      });
  });
}
