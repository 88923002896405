import { IError } from './interfaces';
import AppConfig from './utils/config';

export const api = {
  activate: '/api/customers/me/activation/activate',
  balance: '/api/customers/me/balance',
  baseURL: AppConfig?.REACT_APP_API_URL || 'REACT_APP_API_URL_NOT_CONFIGURED',
  brands: '/api/brands',
  editEmail: 'api/customers/me/email',
  faqs: '/api/content/faq',
  transactions: '/api/customers/me/transactions',
  user: '/api/customers/me',
  validateEmailActivation: '/api/customers/me/activation/validate',
  verifyEmailCode: 'api/customers/me/email/{code}',
  vouchers: '/api/customers/me/vouchers',
  withdraw: '/api/customers/me/withdraw',
  withdrawConfirm: '/api/customers/me/withdraw/{code}',
  withdrawCancel: '/api/me/withdraw/cancel',
};

export const appInsightsInstrumentationKey =
  AppConfig?.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY;

const boostSiteUrl =
  AppConfig?.REACT_APP_MICROSITE_URL ||
  'REACT_APP_MICROSITE_URL_NOT_CONFIGURED';
export const boostSiteUrls = {
  withdrawalTsCs: boostSiteUrl + '/withdrawals',
};

export const errors: { [key: string]: IError } = {
  CredentialsError: {
    message: 'Please provide valid credentials.',
  },
  DateOfBirth: {
    message: 'Invalid date of birth. Use the following format: 17 10 1983',
  },
  NETWORK_ERROR: {
    // code: 'NETWORK_ERROR',
    message:
      'There was a network error. Please check your internet connection and try again.',
  },
  unknown: {
    // code: 'UNKNOWN',
    message: 'An unknown error has occurred.',
  },
  401: {
    // code: 'UNAUTHORIZED',
    message: 'Unauthorized',
    // message: 'User no longer authorized to view this content. Log in again.',
  },
  500: {
    // code: 'INTERNAL_SERVER_ERROR',
    message: 'There was a server error.',
  },
};

export const failedAttemptsNumber = {
  showInfo: 3,
  showWarn: 5,
};

export const routePaths = {
  account: '/account',
  activate: '/activate',
  dashboard: '/',
  error: '/error',
  loggedOut: '/logged-out',
  login: '/login',
  loginDev: '/dev-login',
  orderDetails: '/order-details/:id',
  orderHistory: '/order-history',
  resetPassword: '/reset-password',
  transactions: '/transactions',
  validateActivation: '/validate-activation',
  withdraw: '/withdraw',
  withdrawComplete: '/withdraw-complete',
  withdrawWithDelete: '/withdraw?isDeleting=true',
  withdrawWithDeleteComplete: '/withdraw-with-delete-complete',
  faq: '/faq',
  forbidden: '/forbidden',
};

export interface IIdRouteParams {
  id: string;
  transactions: string;
}

export const contact = {
  supportEmail: 'support@support.com',
  supportTelephone: '012345683',
};

export const gtmCode = AppConfig?.REACT_APP_GTM_CODE || '';

export const isDevelopment = AppConfig?.REACT_APP_IS_DEVELOPMENT;
