// Vendor
import { AxiosResponse } from 'axios';

// Application
import network from './';

export interface IUserLogin {
  email: string;
}

export async function getB2cToken(
  user: IUserLogin
): Promise<AxiosResponse<string>> {
  return network.post('/api/token/b2c', user);
}
