// Application
import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IError } from '../interfaces';
import { getBrands } from '../utils/network/brands';

export interface IBrand {
  id?: string;
  description?: string;
  name?: string;
  images?: IBrandImage;
  offer?: IBrandOffer;
}

export interface IBrandImage {
  logo?: string;
  lifestyleImages?: string[];
}

export interface IValueRange {
  min?: number;
  max?: number;
}

export interface IBrandOffer {
  currency?: string;
  uplift?: number;
  valueRange?: IValueRange;
  voucherTerms?: string;
}

export interface IBrandsState {
  brands: IBrand[] | IError | null;
}

const initialState: IBrandsState = {
  brands: null,
};

export const brands = createModel<RootModel>()({
  name: 'brands',
  state: initialState,
  reducers: {
    updateBrands: (
      state: IBrandsState,
      payload: IBrand[] | IError
    ): IBrandsState => ({
      ...state,
      brands: payload,
    }),
  },
  effects: (dispatch) => ({
    async getBrandsAsync() {
      const brandsResult = await getBrands();
      dispatch.brands.updateBrands(brandsResult);
    },
  }),
});
