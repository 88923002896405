// React core
import React from 'react';

// Vendor
import cx from 'classnames';

// Application
import LogoWhiteAsset from './logo-white.svg';
import LogoAsset from './logo.svg';

// Styles
import styles from './styles.module.scss';

const functionalAlt = 'EQ Boost home';
const decorativeAlt = 'EQ Boost';

interface ILogoProps {
  className?: string;
  white?: boolean;
  clickable?: boolean;
}

const Logo = (props: ILogoProps) => (
  <div className={styles.LogoContainer}>
    <img
      alt={props.clickable ? functionalAlt : decorativeAlt}
      className={cx(styles.Logo, props.className)}
      src={props.white ? LogoWhiteAsset : LogoAsset}
    />
  </div>
);

export default Logo;
