// Application
import { api } from '../../config';
import network from './';

import { IValues as IAccountDetailsValues } from '../../components/AccountDetailsForm';

// Update username
export async function updateEmail(email: string): Promise<any> {
  return network.post(api.editEmail, {
    email,
  });
}

// Verify change username
export async function verifyEmailCode(
  values: IAccountDetailsValues
): Promise<any> {
  return network.post(
    api.verifyEmailCode.replace('{code}', encodeURIComponent(values.code))
  );
}
