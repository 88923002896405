// React core
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

// Vendor
import cx from 'classnames';
import ReactSVG from 'react-svg';

// App
import { routePaths } from '../../config';
import Button from '../Button';
import LogoutButton from '../LogoutButton';
import CloseAsset from './close.svg';
import ActiveAsset from './ellipse.svg';
import MenuAsset from './menu.svg';

// Styles
import styles from './styles.module.scss';

// This can be rewritten using React Router NavLink?
const Nav: FC = () => {
  const [shown, setShown] = useState(false);

  const handleLinkClick = () => {
    setShown(false);
    document.body.classList.remove('no-scroll');
  };

  const handleToggleClick = () => {
    setShown(!shown);
    if (shown) {
      document.body.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll');
    }
  };

  const functionName = (svg: any) => {
    svg.setAttribute('focusable', 'false');
    svg.setAttribute('aria-hidden', 'true');
  };

  return (
    <div>
      <Button
        onClick={handleToggleClick}
        className={styles.toggle}
        mainStyle="basic"
        id="nav-open-btn"
      >
        <span className={styles.toggleTitle}>Menu</span>
        <ReactSVG
          src={MenuAsset}
          className={styles.toggleImage}
          beforeInjection={functionName}
          wrapper="span"
        />
      </Button>
      {shown && (
        <div className={cx(styles.Nav)}>
          <nav>
            <ul className={cx(styles.list)}>
              <li>
                <Link
                  to={routePaths.dashboard}
                  className={styles.link}
                  onClick={handleLinkClick}
                >
                  <ReactSVG src={ActiveAsset} className={styles.linkImage} />
                  <span className={styles.linkText}>Home</span>
                </Link>
              </li>
              <li>
                <Link
                  to={routePaths.orderHistory}
                  className={styles.link}
                  onClick={handleLinkClick}
                >
                  <ReactSVG src={ActiveAsset} className={styles.linkImage} />
                  <span className={styles.linkText}>Your e-vouchers</span>
                </Link>
              </li>
              <li>
                <Link
                  to={routePaths.account}
                  className={styles.link}
                  onClick={handleLinkClick}
                >
                  <ReactSVG src={ActiveAsset} className={styles.linkImage} />
                  <span className={styles.linkText}>Manage your account</span>
                </Link>
              </li>
              <li>
                <a href={routePaths.faq} className={styles.link}>
                  <ReactSVG src={ActiveAsset} className={styles.linkImage} />
                  <span className={styles.linkText}>FAQs</span>
                </a>
              </li>
              <li>
                <LogoutButton
                  className={styles.link}
                  mainStyle="basic"
                  id="nav-log-out-btn"
                >
                  <ReactSVG src={ActiveAsset} className={styles.linkImage} />
                  <span className={styles.linkText}>Log out</span>
                </LogoutButton>
              </li>
            </ul>
          </nav>
          <Button
            onClick={handleToggleClick}
            className={styles.closeButton}
            mainStyle="basic"
            id="nav-close-btn"
            ariaLabel="Close navigation"
          >
            <ReactSVG src={CloseAsset} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Nav;
