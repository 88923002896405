// React core
import React, { FunctionComponent, MouseEvent } from 'react';

// Application
import Button from '../../components/Button';
import Header from '../../components/Header';
import Page from '../../components/Page';
import Section from '../../components/Section';
import Title from '../../components/Title';
import { routePaths } from '../../config';
import { redirectToSignOut } from '../../utils/authentication';

const Forbidden: FunctionComponent = () => {
  const handleRefresh = () => {
    window.location.href = routePaths.dashboard;
  };

  const handleLogout = (e: MouseEvent) => {
    redirectToSignOut();
    e.preventDefault();
  };

  document.title = 'EQ Boost | Forbidden';
  return (
    <Page headerElement={<Header hideBalance={true} hideNav={true} />}>
      <Section mainStyle="fullTextureBg" subStyle="verticalSpacedOut">
        <Title>
          Oh no!{' '}
          <span className="heading-sub">
            We are sorry but it seems that you are not authorized to use this
            resource.
          </span>
        </Title>
      </Section>

      <Section mainStyle="alert">
        <p>Let's see if we can get you back on track.</p>
        <br />
        <Button
          id="btn-reload-error-page"
          type="button"
          mainStyle="primary"
          subStyle="compact"
          onClick={handleRefresh}
        >
          Go to dashboard
        </Button>{' '}
        <Button
          id="btn-logout-error-page"
          type="button"
          mainStyle="secondary"
          subStyle="compact"
          onClick={handleLogout}
        >
          Log out
        </Button>
      </Section>
    </Page>
  );
};

export default Forbidden;
