// React core
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

// App
import { routePaths } from '../../config';
import Balance from '../Balance';
import Logo from '../Logo';
import Nav from '../Nav';

// Types
import { HeadingSizes } from '../../types/headingSizes';

// Styles
import styles from './styles.module.scss';

export interface IHeaderProps {
  children?: ReactNode;
  hideBalance?: boolean;
  hideNav?: boolean;
  headingLevel?: HeadingSizes;
}

const Header = ({
  children,
  hideBalance,
  hideNav,
  headingLevel,
}: IHeaderProps) => (
  <header className={styles.Header}>
    <div className={styles.HeaderInner}>
      <Link
        to={routePaths.dashboard}
        data-test-id="header-logo-link"
        className={styles.HeaderLogo}
      >
        <Logo clickable />
      </Link>
      <div className={styles.right}>
        {children}
        {!hideBalance && <Balance id="balance-header" mainStyle="header" />}
        {!hideNav && <Nav />}
      </div>
    </div>
  </header>
);

export default Header;
